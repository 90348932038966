import i18n from '@/i18n/index.js'

const { t } = i18n.global
export default function getTagSeverityLabel(status) {
  switch (status) {
    case 'accepted_by_customer':
      return { label: t('shared.status.accepted'), severity: 'success' }
    case 'accepted':
      return { label: t('shared.status.accepted'), severity: 'success' }
    case 'fully_paid':
      return { label: t('invoices.fully-paid'), severity: 'success' }
    case 'opened':
      return { label: t('shared.status.opened'), severity: 'success' }
    case 'requested':
      return { label: t('shared.status.requested'), severity: 'plain' }
    case 'sent':
      return { label: t('shared.status.sent'), severity: 'info' }
    case 'rejected':
      return { label: t('shared.status.rejected'), severity: 'danger' }
    case 'closed':
      return { label: t('shared.status.closed'), severity: 'warning' }
    case 'not_paid':
      return { label: t('invoices.not-paid'), severity: 'warning' }
    default:
      return { label: t('shared.status.pending'), severity: 'info' }
  }
}
